import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request, Response } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { StrategyOptions, Strategy, StrategyHandler } from 'o365.pwa.declaration.sw.workbox.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

declare module 'o365.pwa.declaration.sw.strategies.O365OfflineSyncStrategy.d.ts' {
    export interface O365OfflineSyncStrategyOptions extends StrategyOptions {}

    export class O365OfflineSyncStrategy extends Strategy {
        constructor(options?: O365OfflineSyncStrategyOptions);
        _handle(request: Request, handler: StrategyHandler): Promise<Response | undefined>;
    }
}
